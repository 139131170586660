import { Button, IconButton, MailIcon, Row } from '@commonsku/styles';
import React from 'react';

interface WebhooksIntegrationPaywallProps {
    paywallCtaUrl: string
}

const WebhooksIntegrationPaywall = ({
    paywallCtaUrl
}: WebhooksIntegrationPaywallProps) => {
    return <Row>
        <div style={{display: 'flex', background: '#E1F7FA', marginLeft: '20px', marginRight: '20px', padding: '20px'}}>
            <div>
                <p>Enterprise Administrators can configure and connect their webhooks and APIs through the commonsku admin settings.</p>
                <p style={{marginTop: '10px'}}>This allows for integration into 3rd party applications that are not existing integrations with commonsku.</p>
            </div>
            <div style={{marginLeft: '25px'}}>
                <Button variant="secondary" size={'large'} style={{width: '186px'}} onClick={() => { window.location.href = `${paywallCtaUrl}`; }}>Learn More</Button>
                <IconButton variant="error" size={'large'} Icon={MailIcon} style={{marginTop: '10px', width: '186px'}} onClick={() => { window.location.href = 'mailto:support@commonsku.com'; }} >
                    Contact Us
                </IconButton>
            </div>
        </div>
    </Row>;
};

export default WebhooksIntegrationPaywall;
