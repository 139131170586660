import React from 'react';

import AdminTabbedInPopup from './AdminTabbedInPopup';
import { useIdentity } from '../../hooks';
import WebhooksIntegration from './WebhooksIntegration';
import WebhooksIntegrationPaywall from '../../containers/WebhooksIntegrationPaywall';
import APIIntegration from "./APIIntegration";
import useCompanyGeneralDataById from "../../hooks/useCompanyGeneralDataById";
import companyGeneralDataReducer from "../../redux/companyGeneralData";
import Features from '../../constants/Features';
import withReducers from "../../store/withReducers";

const PaywallWrapper = ({ component, ...props }) => {
    const identity = useIdentity();
    const { company } = useCompanyGeneralDataById(identity.company_id);
    const Component = (
        company?.feature_id === Features.DISTRIBUTOR_ENTERPRISE_FEATURE_ID ||
        identity?.user_flags?.includes('WEBHOOKS_ENABLED')
    ) ? component : WebhooksIntegrationPaywall;
    return <Component {...props}/>;
};

const WEBHOOKS_API_MODULES = {
    // 'api': {
    //     'component': () => {
    //         return <PaywallWrapper component={APIIntegration}/>;
    //     },
    //     'title': 'Rest API',
    //     'show': true,
    // },
    'purchase_orders': {
        'component': (props) => {
            return <PaywallWrapper {...props} component={WebhooksIntegration} webhookGroupName={'Purchase Orders'}/>;
        },
        'title': 'Purchase Orders',
        'show': true,
    },
    'sales_orders': {
        'component': (props) => {
            return <PaywallWrapper {...props} component={WebhooksIntegration} webhookGroupName={'Sales Orders'}/>;
        },
        'title': 'Sales Orders',
        'show': true,
    },
    'finance': {
        'component': (props) => {
            return <PaywallWrapper {...props} component={WebhooksIntegration} readOnlyEvents={true} webhookGroupName={'Finance'}/>;
        },
        'title': 'Finance',
        'show': true,
    },
};

const AdminTabbedWebhooksAPI = ({ paywallCtaUrl }) => {
    return <AdminTabbedInPopup moduleMap={WEBHOOKS_API_MODULES} defaultModule={'finance'} paywallCtaUrl={paywallCtaUrl} />;
};

export default withReducers(AdminTabbedWebhooksAPI, {
    companyGeneralDataById: companyGeneralDataReducer,
});
