import {
    createEntityAdapter,
    createSlice,
} from '@reduxjs/toolkit';
import { oauth } from '../utils';
import { Webhook, WebhookGroup } from '../interfaces/Webhook';

/**
 * The entity adapter
 */
const webhookAdapter = createEntityAdapter<Webhook>({
    selectId: (object) => object?.tenant_webhook_id,
});

const webhookGroupAdapter = createEntityAdapter<WebhookGroup>({
    selectId: (object) => object?.tenant_webhook_group_id,
});

const slice = createSlice({
    name: 'tenant_webhooks',
    initialState: {
        loading: 'idle',
        webhooks: webhookAdapter.getInitialState(),
        webhook_groups: webhookGroupAdapter.getInitialState(),
    },
    reducers: {
        webhookGroupUpdated(state, action) {
            webhookGroupAdapter.upsertOne(state.webhook_groups, action);
        },
        webhookGroupReceived(state, action) {
            webhookGroupAdapter.setAll(state.webhook_groups, action);
        },
        webhookAdded(state, action) {
            webhookAdapter.addOne(state.webhooks, action);
        },
        webhookUpdated(state, action) {
            webhookAdapter.upsertOne(state.webhooks, action);
        },
        webhookReceived(state, action) {
            webhookAdapter.setAll(state.webhooks, action);
        },
        webhookRemoved(state, action) {
            webhookAdapter.removeOne(state.webhooks, action);
        },
        loading(state, action) {
            state.loading = 'pending';
        },
        loaded(state, action) {
            state.loading = 'idle';
        },
    }
});

export const {
    loading,
    loaded,
    webhookReceived,
    webhookAdded,
    webhookUpdated,
    webhookRemoved,
    webhookGroupUpdated,
    webhookGroupReceived,
} = slice.actions;

/**
 * The actions (thunk)
 */

export const getTenantWebhooks = () => async (dispatch, getState) => {
    const state = getState();
    let data = [];
    dispatch(loading(state));
    const res = await oauth('GET', `tenant-webhooks`,  {});
    data = res.json;
    dispatch(webhookReceived(res.json.tenant_webhooks));
    dispatch(webhookGroupReceived(res.json.tenant_webhook_groups));
    dispatch(loaded(state));
    return data;
};

export const addTenantWebhooks = (data: any) => async (dispatch, getState) => {
    const state = getState();
    dispatch(loading(state));
    try {
        const res = await oauth('POST', `tenant-webhooks`, data);
        dispatch(webhookAdded(res.json.tenant_webhook));
        return res;
    } finally {
        dispatch(loaded(state));
    }
};
export const updateTenantWebhooks = (id: string, data: any) => async (dispatch, getState) => {
    const state = getState();
    dispatch(loading(state));
    try {
        const res = await oauth('PUT', `tenant-webhooks/${id}`, data);
        dispatch(webhookUpdated(res.json.tenant_webhook));
        return res;
    } finally {
        dispatch(loaded(state));
    }
};

export const updateTenantWebhookPassword = (webhook_group_name: string, password: string) => async (dispatch, getState) => {
    const state = getState();
    dispatch(loading(state));
    try {
        const res = await oauth('PUT', `tenant-webhooks`, {webhook_group_name, password, action: 'update_password'});
        dispatch(webhookGroupUpdated(res.json.tenant_webhook_group));
        return res;
    } finally {
        dispatch(loaded(state));
    }
};

export const deleteTenantWebhook = (id: string) => async (dispatch, getState) => {
    const state = getState();
    dispatch(loading(state));
    try {
        const res = await oauth('DELETE', `tenant-webhooks/${id}`);
        dispatch(webhookRemoved(id));
        return res;
    } finally {
        dispatch(loaded(state));
    }
};

export const webhookSelectors = webhookAdapter.getSelectors();
export const webhookGroupSelectors = webhookGroupAdapter.getSelectors();
export default slice.reducer;

