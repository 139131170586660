import React, { useCallback, useState } from 'react';
import {Button, Row, Col, XIcon, Popup} from '@commonsku/styles';
import TopInfo from '../misc/Info';
import AdminTabbedWebhooksAPI from '../AdminTabbedWebhooksAPI';
import styled from "styled-components";

export default function CompanyCustomWebhook({ paywallCtaUrl }) {

  const [showPopup, setShowPopup] = useState(null);

  const onCloseModule = useCallback(() => {
    setShowPopup(false);
  }, []);

  const FlexPopup = styled(Popup)`
    .popup-content {
      display: flex;
      flex-direction: column;
    }
  `;
  const popup = (
    <FlexPopup
      className='tabbed'
      contentClassName='webhooksAPI'
      style={{
        padding: 0,
        maxWidth: '1600px',
        width: 'calc(100vw - 100px)',
        height: 'calc(100vh - 100px)'
      }}
      noHeader
    >
      <Row>
        <Col>
          <h2 style={{
            // margin: moduleMap[selectedModule]['isTabbed'] ? '1rem' : null,
            margin: '1rem',
            fontSize: '24px',
            fontWeight: 'bold',
          }}>
            Webhooks & API
          </h2>
        </Col>
      </Row>
      <XIcon
        size="large"
        style={{ position: 'absolute', right: 16, cursor: 'pointer', top: 16, zIndex: 9 }}
        onClick={onCloseModule}
      />
      <AdminTabbedWebhooksAPI paywallCtaUrl={paywallCtaUrl} />
    </FlexPopup>
  );

  const configureButton = (
    <div>
      <Button size='small' onClick={() => {
        setShowPopup(true);
      }} key='configure-button'>Configure Webhooks</Button>
    </div>
  );
  return (
    <>
      <TopInfo
        message={[
          'A webhook is an HTTP callback. This option allows commonsku to POST client invoices and supplier bills to',
          <br key="br1"/>,
          'your predefined URL. This would allow the data into your approved 3rd party systems.',
          <br key="br2"/>, <br key="br3"/>,
          <p>Use the Webhooks popup to configure webhooks.</p>
        ]}
        showLink={false}
        buttons={[configureButton]}
        style={{ backgroundColor: '#E1F7FA', color: '#2A4D63', textAlign: 'center', display: 'block' }}
      />
      {showPopup && popup}
    </>
  );

}
