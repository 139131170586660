import React from 'react';
import { useSelector } from 'react-redux';

import Header from './Header';
import MainSection from './MainSection';
import ErrorBoundary from '../components/ErrorBoundary';
import { getPopups } from '../selectors';
import Overlay from '../components/Overlay';

import { reducers } from '../store/configureAdminSettingNew';
import withReducers from '../store/withReducers';
import '../../scss/css/react-toastify.css';
import { ToastContainer } from 'react-toastify';
import AdminSettingsApp from '../components/admin-new/AdminSettingsApp';
import { Theme } from '@commonsku/styles';
import DashboardPageTitle from '../components/dashboard/DashboardPageTitle';
import createGlobalStyle from '../components/helpers/createGlobalStyle';

const GlobalStyle = createGlobalStyle();

const AdminSettingsNewApp = () => {
  const popups = useSelector(getPopups);

  return (
    <Theme>
      <GlobalStyle theme={{ fontFamily: "'skufont-regular', 'sans-serif', 'Roboto'" }} /><ErrorBoundary>
        <Header></Header>
        <MainSection popups={popups}>
          <div className="main-content csku-styles" style={{ paddingRight: '24px', background: '#EDF4F7', paddingBottom: '24px' }}>
            <div className="row full-width" style={{ paddingLeft: '1.25rem' }}>
              <div style={{ paddingLeft: '0.75rem', paddingTop: '1rem' }}><DashboardPageTitle title='Settings' /></div>
              <AdminSettingsApp />
            </div>
          </div>
        </MainSection>
        <Overlay popups={popups} />
        <ToastContainer
          autoClose={3000}
          hideProgressBar={true}
        />
      </ErrorBoundary>
    </Theme>
  );
};

export default withReducers(AdminSettingsNewApp, reducers, true);
