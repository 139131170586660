import React from 'react';
import useCompanyGeneralData from './hooks/useCompanyGeneralData';
import CardConnect from './form/CardConnect';
import { AdminPaymentCreditCardFee } from './payment/AdminPaymentCreditCardFee';

export default function AdminCardConnect() {
    const [{ data }, , , saveExtra] = useCompanyGeneralData();

    return (
        <>
            <CardConnect data={data?.companyData} save={saveExtra} />
            <AdminPaymentCreditCardFee data={data?.companyData} save={saveExtra} />
        </>
    );
}
