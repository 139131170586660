import React, { useState, useCallback, forwardRef } from 'react';

import { Col, Row, Box, Popup, GearIcon, LightIndicator, XIcon } from '@commonsku/styles';
import AdminDecorations from '../../components/admin-decoration-matrix/AdminDecorations';
import AdminTabbedSetup from './AdminTabbedSetup';
import AdminSetupSummary from './AdminSetupSummary';
import { useIdentity } from '../../hooks';
import 'react-virtualized/styles.css';
import '../../../scss/css/admin-decoration.css';
import '../../../scss/css/admin-settings.css';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import AdminTabbedCommission from './AdminTabbedCommission';
import AdminCommissionSummary from './AdminCommissionSummary';
import AdminTabbedIntegration from './AdminTabbedIntegration';
import AdminEmailTemplate from './AdminEmailTemplate';
import AdminCompanySalesTarget from './AdminCompanySalesTarget';
import { getIdentityUtils } from '../../utils';
import ContactAdmin from './ContactAdmin';
import AdminEmailTemplateSummary from './AdminEmailTemplateSummary';
import AdminDecoratorMatrixSummary from './AdminDecoratorMatrixSummary';
import AdminProjectTemplateSummary from './AdminProjectTemplateSummary';
import Avatar from 'react-avatar';
import AdminCompanySalesTargetSummary from './AdminCompanySalesTargetSummary';
import AdminTabbedIntegrationSummary from './AdminTabbedIntegrationSummary';
import AdminTabbedWebhooksAPISummary from './AdminTabbedWebhooksAPISummary';
import AdminTabbedWebhooksAPI from './AdminTabbedWebhooksAPI';
import useTenantUsers from './hooks/useTenantUsers';
import { AdminTagsSummary } from './AdminTagsSummary';
import { AdminCompanyTags } from './AdminCompanyTags';
import './AdminSettings.css';
import styled from 'styled-components';
import AdminTabbedCommissionPaywall from './AdminTabbedCommissionPaywall';
import AdminCompanySalesTargetPaywall from './AdminCompanySalesTargetPaywall';
import WebhooksIntegrationPaywall from "../../containers/WebhooksIntegrationPaywall";
import useCompanyGeneralDataById from "../../hooks/useCompanyGeneralDataById";
import Features from '../../constants/Features';
import withReducers from "../../store/withReducers";
import companyGeneralDataReducer from "../../redux/companyGeneralData";

const FlexPopup = styled(Popup)`
  .popup-content {
    display: flex;
    flex-direction: column;
  }
`;

const AdminSettings = () => {
    const paywallCtaUrl = 'https://meetings.hubspot.com/aaronkucherawy/upgrade-questions';
    const identity = useIdentity();
    const [selectedModule, setSelectedModule] = useState(null);
    const [popupTitle, setPopupTitle] = useState(null);
    const { hasCapabilities } = getIdentityUtils(identity);
    const canEdit = hasCapabilities(['MODIFY-SETTINGS']);
    const canEditTags = hasCapabilities(['CREATE-TAGS', 'MODIFY-TAGS', 'DELETE-TAGS'], true);
    const hasCommissions = hasCapabilities(['HAS-COMMISSIONS']);
    const hasSalesTargets = hasCapabilities(['HAS-SALES-TARGETS']);
    useTenantUsers(); // Load users eagerly
    const { company } = useCompanyGeneralDataById(identity.company_id);

    const companyLink = 'SUPPLIER' === identity.company_type ?
        '/vendor.php?id=' + identity.company_id :
        '/distributor.php?id=' + identity.company_id;
    const hasWebhooksIntegration = (
        company?.feature_id === Features.DISTRIBUTOR_ENTERPRISE_FEATURE_ID ||
        identity?.user_flags?.includes('WEBHOOKS_ENABLED')
    );
    const moduleMap = {
        companyPage: {
            component: null,
            title: 'Company Page',
            link: companyLink,
            content: <LightIndicator name="Configured" on={true} />,
            icon: identity?.company_avatar?.medium ? <Avatar src={identity?.company_avatar?.medium || null} name={identity?.company_name} round={true} size={30} style={{ marginRight: '16px' }} /> : null,
            height: 'SUPPLIER' === identity.company_type ? '160px' : null,
            show: true,
        },
        emailTemplates: {
            component: AdminEmailTemplate,
            title: 'Email Templates',
            isTabbed: true,
            summary: AdminEmailTemplateSummary,
            show: 'SUPPLIER' !== identity.company_type,
        },
        decoration: {
            component: AdminDecorations,
            title: 'Decorator Matrix',
            summary: AdminDecoratorMatrixSummary,
            show: 'SUPPLIER' !== identity.company_type,
            editPermission: 'MODIFY-DECORATOR-MATRIX',
        },
        projectTemplates: {
            component: null,
            title: 'Project Templates',
            link: '/admin_setting_project_templates.php',
            summary: AdminProjectTemplateSummary,
            show: 'SUPPLIER' !== identity.company_type,
        },
        commission: {
            component: hasCommissions ? AdminTabbedCommission : AdminTabbedCommissionPaywall,
            popupTitle: hasCommissions ? 'Commissions' : '',
            title: 'Commissions',
            isTabbed: true,
            summary: AdminCommissionSummary,
            height: '120px',
            show: 'SUPPLIER' !== identity.company_type,
            paywalled: !hasCommissions,
            popupStyle: !hasCommissions ? { maxWidth: '63rem', height: 'auto', margin: 16 } : undefined
        },
        targets: {
            component: hasSalesTargets ? AdminCompanySalesTarget : AdminCompanySalesTargetPaywall,
            popupTitle: hasSalesTargets ? 'Edit Targets' : '',
            title: 'Targets',
            summary: AdminCompanySalesTargetSummary,
            height: '120px',
            show: 'SUPPLIER' !== identity.company_type,
            paywalled: !hasSalesTargets,
            popupStyle: !hasSalesTargets ? { maxWidth: '60rem', height: 'auto' } : undefined
        },
        setup: {
            component: AdminTabbedSetup,
            title: 'Setup',
            isTabbed: true,
            summary: AdminSetupSummary,
            height: 'SUPPLIER' === identity.company_type ? '160px' : '360px',
            show: true,
        },
        integrations: {
            component: AdminTabbedIntegration,
            title: 'Integrations',
            isTabbed: true,
            height: '360px',
            summary: AdminTabbedIntegrationSummary,
            show: 'SUPPLIER' !== identity.company_type,
        },
        tags: {
            component: AdminCompanyTags,
            contentClassName: 'tags-popup-content',
            title: 'Tags',
            popupTitle: 'Manage Tags',
            summary: AdminTagsSummary,
            show: canEditTags && 'SUPPLIER' !== identity.company_type,
        },
        webhooksAPI: {
            component: WebhooksIntegrationPaywall,
            contentClassName: 'webhooksAPI',
            title: 'Webhooks & API',
            popupTitle : 'Upgrade To Enterprise To Unlock Webhooks',
            summary: AdminTabbedWebhooksAPISummary,
            isTabbed: true,
            show: true,
            popupStyle: { maxWidth: '822px', maxHeight: '363px' },
            ...hasWebhooksIntegration ? {
                component: AdminTabbedWebhooksAPI,
                popupTitle : 'Webhooks & API',
                popupStyle: {},
            } : {}
        },
    };

    const onCloseModule = useCallback(() => {
        setSelectedModule(null);
        setPopupTitle(null);
    }, []);

    let popup = null;
    if (selectedModule) {
        const ModuleContainer = moduleMap[selectedModule]['component'];
        const popupStyle = moduleMap[selectedModule]['popupStyle'] ?? {};

        if (ModuleContainer) {
            if (popupTitle == null) {
                setPopupTitle(moduleMap[selectedModule]['popupTitle'] ?? moduleMap[selectedModule]['title']);
            }

            popup = (
                <FlexPopup
                    className={moduleMap[selectedModule]['isTabbed'] ? 'tabbed' : 'non-tabbed'}
                    contentClassName={moduleMap[selectedModule]['contentClassName'] ?? null}
                    style={{
                        padding: moduleMap[selectedModule]['isTabbed'] ? 0 : null,
                        maxWidth: '1600px',
                        width: 'calc(100vw - 100px)',
                        height: 'calc(100vh - 100px)',
                        ...popupStyle
                    }}
                    noHeader
                >
                    {popupTitle !== '' &&
                        <Row>
                            <Col>
                                <h2 style={{
                                    margin: moduleMap[selectedModule]['isTabbed'] ? '1rem' : null,
                                    marginBottom: moduleMap[selectedModule]['isTabbed'] ? null : '1rem',
                                    fontSize: '24px',
                                    fontWeight: 'bold',
                                }}>
                                    {popupTitle}
                                </h2>
                            </Col>
                        </Row>
                    }
                    <XIcon
                      size="large"
                      style={{ position: 'absolute', right: 16, cursor: 'pointer', top: 16, zIndex: 9 }}
                      onClick={onCloseModule}
                    />
                    <ModuleContainer
                        paywallCtaUrl={paywallCtaUrl}
                        identity={identity}
                        inPopup={true}
                        setTitle={setPopupTitle}
                    />
                </FlexPopup>
            );
        }
    }

    const allowedModules = [];
    const list = Object.keys(moduleMap).filter(
        moduleKey => moduleMap[moduleKey].show
    ).map((moduleKey) => {
        const SummaryContainer = moduleMap[moduleKey]['summary'] ?? null;
        const externalLink = moduleMap[moduleKey]['link'] ?? null;
        const height = moduleMap[moduleKey]['height'] ?? null;
        const content = moduleMap[moduleKey]['content'] ?? null;
        const icon = moduleMap[moduleKey]['icon'] ?? null;
        const paywalled = moduleMap[moduleKey]['paywalled'] ?? null;
        const hasModulePermission = moduleMap[moduleKey]['editPermission'] && hasCapabilities(moduleMap[moduleKey]['editPermission']);
        const enabled = canEdit || hasModulePermission;
        if (hasModulePermission) allowedModules.push(moduleMap[moduleKey]['title']);

        return (
            <Col key={moduleKey} lg={6} sm={12}>
                <Box style={{ padding: '15px', margin: '15px', opacity: enabled ? '1' : '0.5', cursor: enabled ? 'pointer' : 'default' }} onClick={(e) => {
                    if (!enabled) return;
                    if (externalLink) {
                        window.open(externalLink);
                    } else {
                        setSelectedModule(moduleKey);
                    }
                }}>
                    <div style={{ height: height || '80px', overflow: 'hidden' }}>
                        {SummaryContainer ? <SummaryContainer title={moduleMap[moduleKey]['title']} paywalled={paywalled} /> :
                            <div style={{ display: 'flex' }}>
                                <div>{icon ? icon : <GearIcon size='large' mr={16} color='#DAE9EE' />}</div>
                                <div>
                                    <h5>{moduleMap[moduleKey]['title']}</h5>
                                    {content ? <div className='tile-summary-content'>{content}</div> : null}
                                </div>
                            </div>
                        }
                    </div>
                </Box>
            </Col>
        );
    });

    let contactMessage = null;
    if (!canEdit && allowedModules.length > 0) {
        contactMessage = "You only have permission to edit " + allowedModules.join(', ') + ". Please contact one of your team's admins to edit other tiles:";
    }

    return (
        <>
            {canEdit ? null : <ContactAdmin message={contactMessage} />}
            <Row>
                {list}
            </Row>
            {popup}
        </>
    );
};

const AdminSettingsWithRef = forwardRef((props, ref) => (
    <DndProvider backend={HTML5Backend}>
        <AdminSettings innerRef={ref} {...props} />
    </DndProvider>
));

export default withReducers(AdminSettingsWithRef, {
    companyGeneralDataById: companyGeneralDataReducer,
});
