import {useCallback, useEffect} from 'react';
import {
    webhookSelectors,
    webhookGroupSelectors,
    getTenantWebhooks,
    updateTenantWebhookPassword,
    updateTenantWebhooks, addTenantWebhooks, deleteTenantWebhook
} from '../../../redux/companyWebhooks';
import { useSelector, useDispatch } from 'react-redux';
import {Webhook, WebhookGroup} from '../../../interfaces/Webhook';
import {useIdentity} from '../../../hooks';

export default function useCompanyWebhooks() {
    const dispatch = useDispatch();
    const identity = useIdentity();
    const reduxState = useSelector((state: any) => {
        return state.tenant_webhooks;
    });
    const webhooksData = webhookSelectors.selectAll(reduxState.webhooks) as Webhook[];
    const webhookGroupsData = webhookGroupSelectors.selectAll(reduxState.webhook_groups) as WebhookGroup[];
    const loading = reduxState.loading;

    useEffect(() => {
        dispatch(getTenantWebhooks());
    }, [dispatch, identity.company_id]);

    const updatePassword = useCallback((webhookGroupName: string, password: string) => {
        return dispatch(updateTenantWebhookPassword(webhookGroupName, password));
    }, [dispatch]);

    const updateOrCreateWebhook = useCallback(({tenant_webhook_id, ...data}) => {
        if (tenant_webhook_id) {
            return dispatch(updateTenantWebhooks(tenant_webhook_id, data)) as unknown as Promise<any>;
        } else {
            return dispatch(addTenantWebhooks(data)) as unknown as Promise<any>;
        }
    }, [dispatch]);

    const deleteWebhook = useCallback((webhook_id: string) => {
        return dispatch(deleteTenantWebhook(webhook_id));
    }, [dispatch]);

    return {
        webhooksData,
        webhookGroupsData,
        loading,
        updatePassword,
        updateOrCreateWebhook,
        deleteWebhook
    };
};
