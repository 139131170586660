import React, { useState } from 'react';
import { useIdentity } from '../../hooks';

const AdminTabbedInPopup = ({ moduleMap, defaultModule, paywallCtaUrl }) => {
    const identity = useIdentity();
    const [selectedModule, setSelectedModule] = useState(defaultModule);
    const ModuleContainer = moduleMap[selectedModule]['component'];
    let module = (
        <>
            <ModuleContainer identity={identity} inPopup={true} paywallCtaUrl={paywallCtaUrl} />
        </>
    );

    const list = Object.keys(moduleMap).filter(
        moduleKey => moduleMap[moduleKey].show
    ).map((moduleKey) => {
        const moduleTitle = moduleMap[moduleKey]['title'];
        return (
            <li key={moduleKey}>
                <a
                    href="/"
                    className={'module-tab' + (moduleKey === selectedModule ? ' active' : '')}
                    onClick={(e) => {
                        e.preventDefault();
                        setSelectedModule(moduleKey);
                    }}
                >
                    {moduleTitle}
                </a>
            </li>
        );
    });

    return (
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'flex-start', height: '100%', overflowY: 'hidden' }}>
            <ul style={{ minWidth: '250px', width: '250px', paddingTop: '20px', backgroundColor: '#E1F7FA', padding: 0, margin: 0, overflowY: 'auto' }}>{list}</ul>
            <div style={{ flexGrow: 1, padding: '0 2rem', height: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
                {module}
            </div>
        </div>
    );
};

export default AdminTabbedInPopup;
