import { find, filter } from 'lodash';
import React, { useEffect, useState } from 'react';
import update from 'immutability-helper';
import {
    EyeIcon,
    LabeledInput, Link,
    Table,
    TBody,
    TH,
    THead,
    TR
} from '@commonsku/styles';
import WebhookSection from './WebhookSection';
import { Webhook } from '../../interfaces/Webhook';
import useCompanyWebhooks from "./hooks/useCompanyWebhooks";
import withReducers from "../../store/withReducers";
import companyWebhookReducer from "../../redux/companyWebhooks";

function WebhooksIntegration({webhookGroupName, readOnlyEvents=false}) {
    const {
        webhooksData, webhookGroupsData,
        updatePassword, updateOrCreateWebhook, deleteWebhook
    } = useCompanyWebhooks();
    const [webhooksPassword, setWebhooksPassword] = useState<string>('');
    const [webhooks, setWebhooks] = useState<Webhook[]>([]);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        if (webhooksData) {
            setWebhooks(filter(webhooksData, { webhook_group_name: webhookGroupName }));
        }
        if (webhookGroupsData) {
            const webhookGroup = find(webhookGroupsData, { webhook_group_name: webhookGroupName });
            setWebhooksPassword(webhookGroup?.password ?? '');
        }
    }, [webhooksData, webhookGroupsData, webhookGroupName]);

    return <div style={{ position: 'relative', height: '100%' }}>
        <div className="column tip" style={{marginBottom: 20}}>
            A webhook is an HTTP callback. This option allows commonsku to POST client invoices and supplier bills to your predefined URL. Contact <a href='mailto:support@commonsku.com'>support@commonsku.com</a> to learn more.
        </div>

        <div style={{width: '375px'}}>
            <LabeledInput
                label={<div>
                    <div style={{float: 'left'}}>Password </div>

                    <div style={{ width: 24, float: 'right' }} onClick={() => setShowPassword(s => !s)}><EyeIcon hide={!showPassword} /></div>

                </div>}
                name='password'
                placeholder='Your webhooks password'
                type={!showPassword ? 'password' : 'text'}
                value={webhooksPassword}
                onBlur={(e) => {
                    updatePassword(webhookGroupName, webhooksPassword);
                }}
                onChange={(e) => {
                    setWebhooksPassword(e.target.value);
                }}
                autoComplete={'off'}
            />
        </div>
        <Table style={{border: '1px solid #d7d7d7'}}>
            <THead>
                <TR style={{background: '#EDF4F7'}}>
                    <TH>Type</TH>
                    <TH>URL</TH>
                    <TH>Active</TH>
                    {!readOnlyEvents && <TH>Actions</TH>}
                </TR>
            </THead>
            <TBody>
                {webhooks?.map((webhook, i) => {
                    return <WebhookSection
                        webhook={webhook}
                        readOnlyEvents={readOnlyEvents}
                        key={i}
                        onChange={async (data) => {
                            const updated = {...webhook, ...data};
                            setWebhooks(update(webhooks, {
                                $splice: [[i, 1, updated]]
                            }));
                            updateOrCreateWebhook(updated);
                        }}
                        onDelete={({ tenant_webhook_id }) => {
                            setWebhooks(update(webhooks, {
                                $splice: [[i, 1]]
                            }));
                            if (tenant_webhook_id) {
                                deleteWebhook(tenant_webhook_id);
                            }
                        }}
                    />;
                })}
            </TBody>
        </Table>

        <div>
            {!readOnlyEvents && <Link style={{ padding: '5px 7px' }} onClick={() => {
                setWebhooks(update(webhooks, {
                    $push: [{
                        tenant_webhook_id: '',
                        webhook_group_name: webhookGroupName,
                        label: '',
                        webhook_url: '',
                        event: '',
                        active: false,
                    }]
                }));
            }}>+ Add Webhook</Link>}
        </div>

    </div>;
}
export default withReducers(WebhooksIntegration, {
    tenant_webhooks: companyWebhookReducer,
});
