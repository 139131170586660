import React from 'react';
import { IntegrationsIcon, LightIndicator } from '@commonsku/styles';
import useCompanyWebhooks from './hooks/useCompanyWebhooks';
import { filter } from 'lodash';
import withReducers from "../../store/withReducers";
import companyWebhookReducer from "../../redux/companyWebhooks";

function AdminTabbedWebhooksAPISummary({ title }) {
    const { webhooksData } = useCompanyWebhooks();
    const isActiveWebhooks = filter(webhooksData, webhook => webhook.active)?.length > 0;

    return (
        <div style={{ display: 'flex' }}>
            <div><IntegrationsIcon size='large' mr={16} color='#DAE9EE' /></div>
            <div>
                <h5>{title}</h5>
                <LightIndicator name={<>Webhooks {isActiveWebhooks ? ' configured' : ' not configured'}</>} on={isActiveWebhooks} />
                <LightIndicator name={'API tokens not configured'} on={false} />
            </div>
        </div>
    );
}

export default withReducers(AdminTabbedWebhooksAPISummary, {
    tenant_webhooks: companyWebhookReducer,
});
