import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, LabeledIconInput, LabeledInput, Row, SharedStyleTypes, Toggle, ToggleLink, Typography } from '@commonsku/styles';
import { useIdentity } from '../../../hooks';
import { getIdentityUtils } from '../../../utils';
import config from '../../../config';

export interface AdminPaymentCreditCardFeeProps {
    data: any,
    save: any,
}

export const AdminPaymentCreditCardFee = ({ ...props }: AdminPaymentCreditCardFeeProps & SharedStyleTypes) => {
    const identity = useIdentity();
    const { hasUserFlags } = getIdentityUtils(identity);
    const defaultValues = useMemo(() => ({
        payment_cc_fee_enabled: 0,
        payment_cc_fee_percentage: config.payment_cc_fee_percentage,
    }), []);

    const defaultErrors = useMemo(() => ({
        payment_cc_fee_percentage: '',
    }), []);

    const [form, setForm] = useState(defaultValues);
    const [formErrors, setFormErrors] = useState(defaultErrors);

    useEffect(() => {
        let values = defaultValues;
        values = {
            payment_cc_fee_enabled: props.data.payment_cc_fee_enabled ?? 0,
            payment_cc_fee_percentage: props.data.payment_cc_fee_percentage ?? config.payment_cc_fee_percentage,
        };
        setForm(values);
    }, [props.data.payment_cc_fee_enabled, props.data.payment_cc_fee_percentage, defaultValues]);

    const setFormFieldValue = useCallback((e) => {
        const target = e.target;
        setForm((form) => ({ ...form, [target.name]: target.value }));
    }, []);

    const validateForm = useCallback((data) => {
        setFormErrors(defaultErrors);
        let errorCount = 0;
        // Check data and set form errors
        for (const field of ['payment_cc_fee_percentage']) {
            if (data[field] === '') {
                setFormErrors((errors) => ({ ...errors, [field]: 'Required' }));
                errorCount++;
            }
        }

        if (errorCount > 0) {
            return false;
        }

        return true;
    }, [defaultErrors]);

    if (!hasUserFlags('PAYMENT-CREDIT-CARD-FEE-BETA')) {
        return null;
    }

    // Only clover connect or stripe with cc enabled, we should show this
    if (!(
        (props.data.credit_card_software === 'STRIPE' && props.data.stripe_payment_cc) ||
        props.data.credit_card_software === 'CARDCONNECT')
    ) {
        return null;
    }

    const getTextInput = (field, label = null) => {
        const hasError = formErrors[field] !== '';
        return (
            <>
                <LabeledIconInput Icon={<div>%</div>} label={label || field} name={field} value={form[field]} onChange={setFormFieldValue} onBlur={(e) => {
                    if (!validateForm(form)) {
                        return;
                    }
                    props.save(form);
                }} />
                {hasError ? <span style={{ color: 'red' }}>{formErrors[field]}</span> : ''}
            </>
        );
    };

    const formFields = (
        <>
            <Row>
                <Col padded sm={6}>
                    <Typography style={{ fontWeight: 'bold' }}>Who pays the credit card fee by default (you can override this)?</Typography>
                    <Toggle size="medium">
                        <ToggleLink onClick={() => {
                            setFormFieldValue({
                                target: {
                                    name: 'payment_cc_fee_enabled',
                                    value: 0,
                                }
                            });
                            props.save({
                                'payment_cc_fee_enabled': 0,
                            });
                        }} selected={+form.payment_cc_fee_enabled === 0}>{identity.company_name}</ToggleLink>
                        <ToggleLink onClick={() => {
                            setFormFieldValue({
                                target: {
                                    name: 'payment_cc_fee_enabled',
                                    value: 1,
                                }
                            });
                            props.save({
                                'payment_cc_fee_enabled': 1,
                            });
                        }} selected={+form.payment_cc_fee_enabled === 1}>The Client</ToggleLink>
                    </Toggle>
                </Col>
                <Col padded sm={6}>
                    {getTextInput('payment_cc_fee_percentage', 'Default Fee')}
                </Col>
                {+form.payment_cc_fee_enabled === 0 ? null :
                    <Col padded sm={12}>
                        A line item for the credit card fee will be automatically added to the invoice.
                    </Col>}
            </Row>
        </>
    );
    return formFields;
};
