import React, { useEffect, useState, useCallback } from 'react';
import {
    ToggleSwitchStyled,
    Input,
    TD,
    TR,
    Select,
    Button,
    IconButton,
    TrashIcon,
    ConfirmPopup,
    AlertNotification,
    Row,
    colors,
    XIcon,
    Csku, Popup
} from '@commonsku/styles';
import {Webhook} from "../../interfaces/Webhook";
import {createOptions} from "../../utils";
import { useDebounce } from '../../hooks';

export type WebhookSectionProps = {
    readOnlyEvents: boolean
    onChange: (params: Partial<Webhook>) => void;
    onDelete: (params: Partial<Webhook>) => void;
    webhook: Webhook;
};

const WebhookGroups = {
    'Purchase Orders': [
        {'key': 'PO.NEW', 'value': 'Purchase Order Created'},
        {'key': 'PO.UPDATED', 'value': 'Purchase Order Modified'},
    ],
    'Sales Orders': [
        {'key': 'SO.UPDATED', 'value': 'Sales Order Modified'},
    ],
    'Finance': [
        {'key': 'EXPORT.INVOICES', 'value': 'Export Invoices'},
        {'key': 'EXPORT.BILLS', 'value': 'Export Bills'},
    ],
};

const UrlInput = ({ value, onChange, ...props }) => {
    const [_value, setValue] = useState(value ?? '');
    const debouncedOnChange = useDebounce((...args) => {
        return onChange(...args);
    });
    useEffect(() => {
        setValue(value);
    }, [value]);
    return <Input {...props}
        onChange={(e) => {
            setValue(e.target.value);
            return debouncedOnChange(e);
        }}
        value={_value}
    />
}

export default function WebhookSection({ webhook, readOnlyEvents, onChange, onDelete }: WebhookSectionProps) {
    const [showConfirmPopup, setConfirmPopup] = useState(false);
    const [showAlert, setAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')
    const events = WebhookGroups[webhook.webhook_group_name] ?? [];
    const { webhook_url } = webhook || {};

    const options = createOptions(events, "key", "value");

    const validateUrl = useCallback((url: string) => {
        try {
            const res = new URL(url);
            if (!/http?/.test(res.protocol)) {
                setErrorMessage('Is Not Valid URL');
                return;
            }
        } catch (e) {
            setErrorMessage('Is Not Valid URL');
            return;
        }
        setErrorMessage('');
    }, []);

    useEffect(() => {
        validateUrl(webhook_url);
    }, [webhook_url]);

    return <>
        <TR>
            <TD style={{width: '25%', backgroundColor: '#FFFFFF'}}>
                <Select
                    isClearable={false}
                    value={options.find((op) => op.value === webhook.event)}
                    onChange={(e) => onChange({event: e.value})}
                    options={options}
                    isDisabled={readOnlyEvents}
                    placeholder="Select Event"
                />
            </TD>
            <TD style={{width: '45%', backgroundColor: '#FFFFFF'}}>
                <UrlInput
                    style={{marginBottom: '0',borderColor: errorMessage ? 'red' : null}}
                    onChange={(e) => {
                        validateUrl(e.target.value);
                        onChange({webhook_url: e.target.value})
                    }}
                    value={webhook.webhook_url}
                />
            </TD>
            <TD style={{width: '15%', backgroundColor: '#FFFFFF'}}>
                <ToggleSwitchStyled
                    onClick={() => {
                        if (!webhook.webhook_url || !webhook.event || errorMessage) {
                            setAlert(true);
                            return false;
                        }
                        onChange({ active: !webhook.active })
                    }}
                    style={!webhook.webhook_url || !webhook.event || errorMessage ? {float: 'left', pointerEvents: "none", opacity: "0.4"} : {float: 'left'}}
                    selected={webhook.active}
                />
            </TD>
            {!readOnlyEvents && <TD style={{width: '15%',backgroundColor: '#FFFFFF'}}>
                <IconButton
                    style={{ background: '#D10411',borderColor: 'transparent' }}
                    iconPosition='left'
                    size='small'
                    Icon={TrashIcon}
                    onClick={() => {
                        setConfirmPopup(true);
                    }}
                >Delete</IconButton>
            </TD>
            }
        </TR>
        {showConfirmPopup && <ConfirmPopup
            confirmText="Are you sure you want to delete this webhook?"
            onDelete={()=>{onDelete(webhook);setConfirmPopup(false)}}
            onClose={() => setConfirmPopup(false)}
        />}
        {showAlert && <Popup
            title="Error"
            style={{width: '500px', height: '200px'}}
            onClose={() => setAlert(false)}
            header={
                <Row className='popup-header' justify="space-between" wrap="nowrap" style={{ alignItems: 'flex-start' }}>
                    <h2 style={{
                        margin: '0.2em',
                        fontFamily: 'skufont-demibold',
                        fontSize: 26,
                        fontWeight: 500,
                        lineHeight: '26px',
                        color: colors.neutrals[90],
                    }}>Add Token</h2>
                    <div>
                        <XIcon cursor={'pointer'} onClick={() => setAlert(false)} mt={2} ml={10} size='large' />
                    </div>
                </Row>
            }
        >
            <div>
                <AlertNotification
                    alertType='warn'
                    learnMore={false}
                    style={{
                        marginTop: 10,
                        whiteSpace: 'pre-wrap',
                    }}
                >Unable to activate webhook until both Type and URL are set..
                </AlertNotification>


                <Csku className="popup-buttons" style={{paddingLeft: '319px'}}>
                    <Button variant="secondary" onClick={() => setAlert(false)} mr={24}>
                        Ok
                    </Button>
                </Csku>
            </div>
        </Popup>
        }
    </>;
}
